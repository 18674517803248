import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Truevined - No Sentence",
  "featuredImage": "images/No-2BSentence-2B-2Bartwork-2B-2Bsmall.jpg",
  "coverAlt": "Truevined - No Sentence.",
  "description": "Creative Director, Branding & Graphic Design for Truevined's music addressing social change.",
  "date": "2019-01-03",
  "dateModified": "2019-01-03",
  "category": "portfolio",
  "pinned": false,
  "featured": false,
  "tags": ["Graphic Design", "Music artwork"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Artwork for a collection of singles Truevined released in 2020.`}</p>
    <p>{`Song: Truevined - No Sentence`}</p>
    <p>{`Listen: `}<a parentName="p" {...{
        "href": "https://open.spotify.com/album/59zxRdrqXFDY9jlF372YpK"
      }}>{`https://open.spotify.com/album/59zxRdrqXFDY9jlF372YpK`}</a></p>
    <p>{`The song “No Sentence” is about social injustice. I came up with a concept to use a electro cardiogram device screenshot and replace it’s real data with meta data about the song.`}</p>
    <h2 {...{
      "id": "screenshots",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#screenshots",
        "aria-label": "screenshots permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Screenshots`}</h2>
    <p><img parentName="p" {...{
        "src": "images/No-2BSentence-2B-2Bartwork-2B-2Bsmall-300x300.jpg",
        "alt": "Truevined - No Sentence"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      